<template>
  <div class="login-panel">
    <div class="login-panel-logo d-flex justify-content-center">
      <img src="@/assets/images/logo-vision.svg" alt="vision">
    </div>
    <form @submit.prevent="resetPass">
      <div class="login-panel-wrapper">
        <div class="form-group full-width">
          <label for="email">Email</label>
          <input type="email" id="email" class="form-control" v-model="email" required>
        </div>
        <div class="form-group full-width" v-if="result">{{result}}</div>
        <button :disabled="ui.submit" @click="resetPass">
            <div class="spinner-border spinner-border-sm" role="status" v-if="ui.submit">
              <span class="visually-hidden">Loading...</span>
            </div>
            <span v-else>Reset Password</span>
        </button>
      </div>
    </form>
    <div class="d-flex align-items-center justify-content-center mt-3">
      <div class="powered-by">
        From
      </div>
      <img src="@/assets/images/logo-clearmortgagecapital.svg" alt="vision">
    </div>
    <p class="text-center mt-4 mb-0 color-red" v-if="error">{{ error }}</p>
  </div>
</template>

<script>
export default {
  name: "PasswordReset",
  data() {
    return {
      email: '',
      result: '',
      error: null,
      ui: {
        submit: false
      }
    }
  },
  methods: {
    resetPass() {
      this.ui.submit = true
      this.$http.get(`/api/v1/staff/reset-password-by-email/${this.email}`)
        .then((res) => {
          this.email = ''
          this.result = res.data.message
        })
        .catch((err) => {
          this.alertError(err.response.statusText)
        })
        .finally(() => {
          this.ui.submit = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.login-panel {
  min-height: fit-content;
  max-width: 363px;
  width: 100%;
  border: 1px solid #E7E8E8;
  border-radius: 8px;
  background-color: #FFFFFF;
  box-shadow: 0 18px 32px 0 rgba(0,0,0,0.06);
  margin: 50px auto;
  padding: 20.5px 30px;
  &-subtitle {
    opacity: 0.7;
    color: #000000;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
    margin-bottom: 24px;
  }
  &-logo {
    padding-bottom: 24px;
    border-bottom: 1px solid #E7E8E8;
    margin-bottom: 24px;
  }
  &-wrapper {
    padding-bottom: 30px;
    border-bottom: 1px solid #E7E8E8;
  }
  .forgot-pass {
    color: #0078FF;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    text-decoration: none;
  }
  label {
    color: #000000;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
    margin-bottom: 11px;
  }
  input {
    display: block;
    height: 44px;
    width: 100%;
    border: 1px solid #D4D4D4;
    border-radius: 8px;
    background-color: #FFFFFF;
    opacity: 1;
    color: #000000;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 14px;
    margin-bottom: 18px;
    padding-left: 12px;
    &:last-child {
      margin-bottom: 12px;
    }
    &::placeholder {
      opacity: 0.5;
      color: #000000;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 14px;
    }
  }
  button {
    width: 100%;
    height: 44px;
    border-radius: 8px;
    background-color: #266FE5;
    border: none;
    color: #FFFFFF;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 14px;
    margin-top: 18px;
    transition: all .3s;
    &:hover {
      background-color: #175fd2;
    }
  }
  .powered-by {
    color: #0B1B1C;
    font-size: 11px;
    letter-spacing: 0;
    line-height: 12px;
    margin-right: 12px;
  }
}
.g-recaptcha {
  transform-origin: left top;
  -webkit-transform-origin: left top;
}
</style>
